import React from "react";
import "./Developer.css";
import img1 from "./../../../Images/governance-forum-img.png";
import img2 from "./../../../Images/governance-forum-img2.png";
import img3 from "./../../../Images/governance-forum-img-3.png";
import bountyimg from "./../../../Images/bounty-img.png";

export default function Developer() {
  return (
    <>
      <section className="developer-img">
        <div className="container-section">
          <div className="developer">
            <p>Developer</p>
            <h4>Build with the Ramzswap Protocol.</h4>
            <p>
              Dive into the world of DeFi apps, integrations, and <br />
              developer tooling built on top of the Uniswap Protocol.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-developer">
                <img src={img1} alt="" />
                <h5 className="ramz-title">What is Ramzswap?</h5>
                <p>
                  Learn about the Ramzswap protocols core concepts: Swaps,
                  pools, Concentrated Liqiudity, and more.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-developer">
                <img src={img2} alt="" />
                <h5 className="ramz-title">Smart Contract Overview</h5>
                <p>
                  Review the architecture of the Ramzswap Protocol smart
                  contracts, made up of the Core and periphery libraries.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-developer">
                <img src={img3} alt="" />
                <h5 className="ramz-title">V3 SDK</h5>
                <p>
                  Explore the SDK, which helps developers interact with
                  JavaScript environments.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-developer white">V3 Whitepaper</div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-developer white">Github</div>
            </div>
          </div>
          <div className="horizontal-line"></div>
        </div>
      </section>
      <section className="second-section-dev">
        <div className="container-section">
          <div className="apply-section">
            <div>
              <h4>Apply to the Ramzswap Grants Program</h4>
              <p className="ramzon-des">
                The Ramzswap Grants Program empowers independent builders and
                contributors
                <br /> with resources to experiment. Providing both funding and
                feedback, the Grants
                <br /> team cultivates talent that enriches the Uniswap
                ecosystem experience and <br />
                sets the Protocol on a path to sustainable community-driven
                growth.
              </p>
              <button className="secondary-btn">See more</button>
            </div>
          </div>
          <div className="horizontal-line"></div>
          <div className="row bount-bug">
            {" "}
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div>
                <img src={bountyimg} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="bug-bu">
                <h4>Bug Bounty</h4>
                <p>
                  Uniswap Labs is offering a reward of up to $500,000<br/> for
                  critical and high severity bugs in the Protocol’s<br/> core
                  contracts. Happy hunting!
                </p>
              </div>
              <div className="learn-bu">
                <button className="secondary-btn report">
                  Submit a report
                </button>
              </div>
              <div className="learn-bu">
                <button className="secondary-btn report">
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
