import React from "react";
import "./Ecosystem.css";
import ecocardimg1 from "./../../../Images/governance-forum-img.png";

export default function Ecosystem() {
  return (
    <>
      <section className="section-image-eco">
        <div className="container-section">
          <div className="eco-content">
            <h2 className="expolore-head">
              Explore the RAMZSwap
              <br /> Ecosystem
            </h2>
            <p>
              Swap, earn, vote, and more with hundreds of <br />
              DeFi apps, integrations, and tools built on <br />
              the Ramzswap Protocol.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-section eco-second-section">
          <div>
            <p>
              Browse and search projects built on the RAMZSWAP Protocol. *<br />
              Building something cool with Ramzswap? Submit your project.
            </p>
            <div>
              <input type="text" placeholder="Search" className="search-eco" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-6">
              <div></div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6"></div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6"></div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6"></div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-section">
          <div className="row eco-card-section">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">Revert</div>
                  <p className="revert-text">
                    Actionable analytics for Defi liquidity providers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">Uniswaptimizer</div>
                  <p className="revert-text">
                    Uniswap v3 Optimal Pool Position Estimation Tool.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">Coinbase Wallet</div>
                  <p className="revert-text">
                    The secure app to store crypto yourself.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">Compound</div>
                  <p className="revert-text">
                    An autonomous interest rate protocol.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row eco-card-section">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">InstaDapp</div>
                  <p className="revert-text">
                    Platform to for buiding DeFi apps.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">Reflexer</div>
                  <p className="revert-text">Liquidation Protected Loans.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">Lixir</div>
                  <p className="revert-text">
                    Active liquidity provision solution for Uniswap v3
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="ecocard-outer">
                <div className="ecocard">
                  <img src={ecocardimg1} alt="" />
                  <div>
                    <span className="tool">Tool</span>
                  </div>
                </div>
                <div>
                  <div className="revert-title">Coin98 Wallet</div>
                  <p className="revert-text">All in one DeFi Solution.</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="information-section">
              * Information about applications, services, and websites that are
              not developed, controlled, or tested by Ramzswap Labs is provided
              without recommendation or endorsement. Ramzswap Labs assumes no
              responsibility with regard to the selection, performance,
              security, accuracy, or use of third-party offerings. For more
              information on each project, please contact the developer.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
