import React from "react";
import "./Governance.css";
import governanceimg1 from "./../../../Images/governance-forum-img.png";
import governanceimg2 from "./../../../Images/governance-forum-img2.png";
import governanceimg3 from "./../../../Images/governance-forum-img-3.png";

export default function Governance() {
  return (
    <>
      <section className="section-img">
     
        <div className="container-section">
          <div className="content-section">
            <div>
              <div>GOVERNANCE</div>
              <div>
                <div className="governance-title">
                  The RAMZswap is a public good owned <br />
                  and governed by UNI token holders
                </div>
              </div>
            </div>
          </div>
          <div className="row circle-box">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="circle"></div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div>
                <h3 className="">The UNI Token</h3>
                <p>
                  The Ramzswap token enables community ownership and active
                  <br />
                  stewardship of the Protocol. UNI holders govern the Protocol
                  <br />
                  through an on-chain governance process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-section">
          <div className="governance-app-section-1">
            <div>THE GOVERNANCE APPS</div>
            <div>Share your opinions and shape the future of the protocol</div>
          </div>
          <div className="row governance-row-card">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="governance-card center">
                <img src={governanceimg1} alt="" />
                <div className="governance-forum">Governance Forum</div>
                <p>
                  A forum for governance-related discussion. share proposals,
                  provide feedback, and shape the future of the protocol with
                  Ramzswap community
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              {" "}
              <div className="governance-card center">
                <img src={governanceimg2} alt="" />
                <div className="governance-forum">Governance Portal</div>
                <p>
                  The official governance voting portal. review live governance
                  proposals and cast your vote onchain
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              {" "}
              <div className="governance-card center">
                <img src={governanceimg3} alt="" />
                <div className="governance-forum">Snapshot</div>
                <p>
                  A simple off-chain voting interface for community members to
                  signal sentiment during the early stages of proposals life
                  cycle.
                </p>
              </div>
            </div>
          </div>
          <div className="horizontal-line"></div>
          <div className="governance-app-section">
            <div>THE GOVERNANCE PROCESS</div>
            <div className="row process">
              <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="phase-1">Phase 1: Temperature Check</div>
                <div className="phase-1">Phase 2: Consensus Check</div>
                <div className="phase-1">Phase 3: Governance Proposal</div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="governance-app-section-1">
                  <div className="temperature-top">Temperature Check</div>
                  <p>
                    The Temperature Check process determines whether there is
                    sufficient will to make changes to the status quo. At the
                    end of the two days, a majority vote with a 25k UNI yes-vote
                    threshold wins. If the Temperature check does not suggest a
                    change from the status quo, the topic will be closed on the
                    governance site. If the Temperature Check does suggest a
                    change, proceed to Stage 2: Consensus Check
                  </p>
                  <div className="temperature-top">Consensus Check</div>
                  <p>
                    The Consensus Check process incorporates feedback from the
                    Temperature Check and establishes formal discussion around a
                    potential proposal. Consensus Check is accompanied by
                    another off-chain vote. At the end of five days, a majority
                    vote with a 50k UNI yes-vote threshold wins.
                  </p>

                  <div className="temperature-top">Governance Proposal</div>
                  <p>
                    A Governance Proposal is the final step in the governance
                    process. The proposal should incorporate feedback from the
                    Consensus Check and is accompaniedby the executable on-chain
                    code. In order to submit an on-change Governance proposal, a
                    delegate must have a minimum balance of 2m UNI. The voting
                    period lasts 7 days and a mojority vote with a 40m UNI
                    yes-vote threshold wins.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
