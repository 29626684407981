import React from "react";
import "./Community.css";
import discord from "./../../../Images/discord.png";
import twitter from "./../../../Images/twitter.png";
import image3 from "./../../../Images/image3.png";

export default function Community() {
  return (
    <>
      <section className="community-img">
        <div className="container-section">
          <div className="community">
            <span>COMMUNITY</span>
            <h3 className="eleme-h-section">
              <strong className="elemenet-str">
                The RAMZswap community is an ecosystem of users,
                <br />{" "}
              </strong>
              developers, designers, and educators
            </h3>
          </div>
          <div className="horizontal-line"></div>
          <div className="eleme-join">
            <h3>Join the conversation.</h3>
            <p>
              Ramzswap’s global and vibrant community drives the success of the
              Protocol. <br />
              Join the conversation on Discord, Twitter, and Reddit to stay up
              to date on the
              <br /> latest community news.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-section">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="discord-card">
                <div>
                  <img src={discord} className="discord" />
                </div>
                <div className="discord-content">
                  <h5 className="dis-con">Discord</h5>
                  <p>Ask questions and engage with the Ramzswap Community.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="twitter-card">
                <div>
                  <img src={twitter} className="twitter" />
                </div>
                <div className="twitter-content">
                  <h5 className="ramz-con">Ramzswap</h5>
                  <p>Follow the latest news form Ramzswap Labs.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row section-row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="discord-card">
                <div>
                  <img src={discord} className="discord" />
                </div>
                <div className="discord-content">
                  <h5 className="dis-con">Discord</h5>
                  <p>Ask questions and engage with the Ramzswap Community.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="twitter-card">
                <div>
                  <img src={twitter} className="twitter" />
                </div>
                <div className="twitter-content">
                  <h5 className="ramz-con">Ramzswap</h5>
                  <p>Follow the latest news form Ramzswap Labs.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row section-row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="discord-card">
                <div>
                  <img src={discord} className="discord" />
                </div>
                <div className="discord-content">
                  <h5 className="dis-con">Discord</h5>
                  <p>Ask questions and engage with the Ramzswap Community.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="twitter-card">
                <div>
                  <img src={twitter} className="twitter" />
                </div>
                <div className="twitter-content">
                  <h5 className="ramz-con">Ramzswap</h5>
                  <p>Follow the latest news form Ramzswap Labs.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="horizontal-line"></div>
          <div className="eleme-h-section">
            <div className="eleme-h-section">
              <h4>
                Ramzswap community members contribute and express <br />
                themselves through mediums beyond finance
              </h4>
              <p>We believe that serious play can lead to serious things.</p>
              <div className="image3">
                {" "}
                <img src={image3} alt="" />
              </div>
            </div>
          </div>
        
          <div className="row">
            {" "}
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="unicode">
                <h4>Unicode</h4>
                <p>A virtual hackathon focused on the Ramzswap Protocol.</p>
                <button className="secondary-btn">See more</button>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="unicode">
                <h4>PplPleasr x*y=k</h4>
                <p>
                  @pplpleasr sold the Ramzswap V3 launch video for 310 ETH.
                  Proceeds went to charity.
                </p>
                <button className="secondary-btn">See more</button>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              {" "}
              <div className="unicode">
                <h4>Unisocks</h4>
                <p>Physical socks mapped to tokens on Ethereum.</p>
                <button className="secondary-btn">See more</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
