import React, { useEffect, useLayoutEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import logo from "../../Images/ramz.png";

export default function Navbar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);

  const [theme, setTheme] = useState("light-theme");
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <>
      <section>
        <div className="container-section">
          <div className="nav">
            <NavLink to="/">
              {" "}
              <div className="m-q-b-d-n logo">
                <img src={logo} className="w-5" />
              </div>
            </NavLink>
            <div>
              <ul className="nav-ul m-q-b-d-n">
                <NavLink to="/ecosystem">
                  <li className="li-section">Ecosystem</li>
                </NavLink>
                <NavLink to="/community">
                  <li className="li-section">Community</li>
                </NavLink>
                <NavLink to="/governance">
                  {" "}
                  <li className="li-section">Governance</li>
                </NavLink>
                <NavLink to="/developer">
                  <li className="li-section">Developers</li>
                </NavLink>{" "}
                <li className="li-section">Blog</li>
                <NavLink to="/faq">
                  <li className="li-section">FAQ</li>
                </NavLink>
                {/* <NavLink to="">
                <li>Jobs</li>
              </NavLink>
              <NavLink to="/swap">
                <li>Swap</li>
              </NavLink> */}
                <NavLink to="https://app.ramzswap.com" target="_blank">
                  <li>
                    <button className="launch-bu">Launch App</button>
                  </li>
                </NavLink>
              </ul>
              <ul className="m-q-a-d-n">
                {" "}
                <li>
                  {" "}
                  <Button onClick={handleShow} className="button-mobile">
                    <FaBars className="bars" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/*-------------------- OFFCANVAS ------------------------------- */}

      <Offcanvas show={show} onHide={handleClose} className="pa-1">
        <Offcanvas.Header>
          <Offcanvas.Title>
            {" "}
            <div className="image-dog1">
              <a href="/" target="_blank" className="mobile-logo">
                <div>
                  <img src={logo} className="w-5" />
                </div>
              </a>
            </div>
          </Offcanvas.Title>
          <ImCross onClick={handleClose} color="white" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="l-s-t-n head-mo">
            <NavLink to="/ecosystem">
              {" "}
              <li className="mobile-nav">Ecosystem</li>
            </NavLink>
            <NavLink to="/community">
              {" "}
              <li className="mobile-nav">Community</li>
            </NavLink>
            <NavLink to="/governance">
              <li className="mobile-nav">Governance</li>
            </NavLink>
            <NavLink to="/developer">
              <li className="mobile-nav">Developers</li>
            </NavLink>
            <NavLink to="">
              <li className="mobile-nav">Blog</li>
            </NavLink>
            <NavLink to="/faq">
              {" "}
              <li className="mobile-nav">FAQ</li>
            </NavLink>
            <NavLink to="https://app.ramzswap.com" target="_blank">
              {" "}
              <li className="mobile-nav">
                <button className="launch-bu">Launch App</button>
              </li>
            </NavLink>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
