import React from "react";
import "./Home.css";

export default function Home() {
  return (
    <>
      <section className="section-img">
        <div className="container-section">
          <div className="title-head">
            <h1 className="ramz-title">RAMZSWAP </h1>
            <p className="home-para">
              Swap, earn, and build on the leading decentralized crypto <br />
              trading protocol.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-section ">
          {" "}
          <div className="row trade">
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="number-count">
                <h3 className="">$1.4t+</h3>
                <span className="trade-span">Trade Volume</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="number-count">
                <h3>152M+</h3>
                <span className="trade-span">All Time Trades</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="number-count">
                <h3>300+</h3>
                <span className="trade-span">Intergrations</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="number-count">
                <h3>4,400+</h3>
                <span className="trade-span">Community Delegation</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bottom-section">
        <div className="container-section ecosystem">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="content-box">
                <span className="ramz-span" >RAMZSWAP ECOSYSTEM → </span>
                <h5 className="growing-main"> A growing network of<br/> DeFi Apps.</h5>
                <p>
                  Developers, traders, and liquidity providers participate
                  together in a financial marketplace that is open and
                  accessible to all.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="box"></div>
            </div>
          </div>
        </div>
        <div className="container-section ecosystem">
          <div className="row">
            <div>
              <h5>DEVELOPERS → </h5>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-12 developers-top">
              <div className="developers-box">
                <div className="superbox">
                  <h5 className="super-ti">Superpowers for DeFi developers.</h5>

                  <p className="build-cont">
                    Build Defi apps and tools on the largest crypto project on
                    Ethereum. Get started with quick start guides, protocol
                    documentation, a Javascript SDK, and fully open source code.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="small-box">
                    <h5 className="h5-title">V3 Whitepaper </h5>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="small-box">
                    <h5 className="h5-title">Github </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-mg-4 col-md-4 col-sm-12 col-12 developers-top">
              <div className="developers-box1">
                <div className="developer-inside">
                  <h5>Apply for funding from the Ramzswap Grants Program</h5>
                  <p className="build-description">
                    Get paid to build the future of finance. Uniswap Governance
                    offers grant funding for people building apps, tools, and
                    activities on the RAMZSWAP Protocol.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-section ecosystem">
          <div className="row">
            <div>
              <h5 className="h5-title">PROTOCOL GOVERNANCE → </h5>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="governance-box">
                <h5 className="h5-title">Governed by the community.</h5>
                <p>
                  The Ramzswap Protocol is managed by a global community of UNI
                  token holders and delegates.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="">
                <div className=" ">
                  <div className="governance-box1">
                    <div>
                      <h5 className="h5-title">Governance Forum</h5>
                      <p>
                        Participate by proposing upgrades and discussing the
                        future of the protocol with the Uniswap community.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="governance-box1 sizes">
                    {" "}
                    <div>
                      <h5 className="h5-title">Sybil</h5>
                      <p>
                        Vote on offchain proposals with the Snapshot interface.
                        Votes are Weighted by the number of UNI delegates.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" ">
                  <div className="governance-box1 sizes">
                    {" "}
                    <div>
                      <h5 className="h5-title">Governance Portal</h5>
                      <p>
                        Vote on official Ramzswap governance proposals and view
                        past proposals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
