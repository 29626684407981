import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section>
        <div className="container-section footer-section">
          <div>
            <ul className="footer-ul">
              <NavLink to="/ecosystem"><li className="footer-tab">Ecosystem</li></NavLink>
              <NavLink to="/community"><li className="footer-tab">Community</li></NavLink>
              <NavLink to="/governance"><li className="footer-tab">Governance</li></NavLink>
             <NavLink to="developer"> <li className="footer-tab">Developers</li></NavLink>
              
              <NavLink to="/faq"> <li className="footer-tab responsive-fo">FAQ</li></NavLink>
              <NavLink ><li className="footer-tab responsive-fo">Privacy Policy</li></NavLink>
            <NavLink>  <li className="footer-tab responsive-fo">Trademark Policy</li></NavLink>
             <NavLink><li className="footer-tab responsive-fo">Security</li></NavLink>
            </ul>
            <ul className="footer-ul second-resp">
          <NavLink>  <li className="footer-tab">Blog</li></NavLink>
             <NavLink to="/faq"> <li className="footer-tab">FAQ</li></NavLink>
             <NavLink> <li className="footer-tab">Privacy Policy</li></NavLink>
           <NavLink>   <li className="footer-tab">Trademark Policy</li></NavLink>
              <NavLink><li className="footer-tab">Security</li></NavLink>
            </ul>
          </div>
          <div className="footer-des">
            <p>Media inquires for Ramzswap - Contact </p>
          </div>
        </div>
      </section>
    </>
  );
}
