import React from "react";
import "./Swap.css";
import { AiOutlineSetting, AiOutlineArrowDown } from "react-icons/ai";
import eth from "./../../../Images/eth.png";
import { RiArrowDropDownLine } from "react-icons/ri";

export default function Swap() {
  return (
    <>
      <section>
        <div className="container-section boxes-sec">
          <div className="box-swap">
            <div className="swap-section">
              <div className="first-swap">
                <div className="buttons-swap">
                  <span>Swap</span>
                  <span>Buy</span>
                </div>
                <div className="setting-icon">
                  <AiOutlineSetting />
                </div>
              </div>
              <div className="eth-button">
                <input placeholder="0" className="input-eth" />
                <div>
                  <div className="eth-btn">
                    <img src={eth} alt="" className="eth-icon" />
                    <span>ETH</span>
                    <RiArrowDropDownLine />
                  </div>
                </div>
              </div>
              <div className="swap-btn">
                <div className="down-arrow">
                  <AiOutlineArrowDown className="arrow-down" />
                </div>
              </div>
              <div className="eth-se">
                <div className="eth-button">
                  <input placeholder="0" className="input-eth" />
                  <div>
                    <div className="eth-btn-select">
                      <span>Select token</span>
                      <RiArrowDropDownLine />
                    </div>
                  </div>
                </div>
              </div>
              <div className="connect-wallet">
                <button className="wallet-button">Connect Wallet</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
