import React from "react";
import "./Faq.css";

export default function Faq() {
  return (
    <>
      <section>
        <div className="container-section setting-faq">
          <div className="boxes ">
            <div className="box1 setting-of-size">
              <div>
                {" "}
                <h2>Frequently Asked Questions</h2>
              </div>
              <div className="section-top-gap">
                <h4>What is Uniswap Protocol?</h4>
                <br />

                <p>
                  The Uniswap Protocol is an open-source protocol for providing
                  liquidity and trading ERC20 tokens on Ethereum. It eliminates
                  trusted intermediaries and unnecessary forms of rent
                  extraction, allowing for safe, accessible, and efficient
                  exchange activity. The protocol is non-upgradable and designed
                  to be censorship resistant.
                  <br />
                  <br />
                  The Uniswap Protocol and the Uniswap Interface were developed
                  by Uniswap Labs.
                  <br />
                  <br />
                  Check out the Introduction section of our docs for more info
                  on the different roles played by Labs, the Interface, and the
                  Protocol.
                </p>
              </div>
              <div className="horizontal-line"></div>
              <div className="uniswap">
                <h4>How do I use the Uniswap Protocol?</h4>
                <br />
                <p>
                  To create a new liquidity pool, provide liquidity, swap
                  tokens, or vote on governance proposals, head over to the
                  Uniswap Interface and connect a Web3 wallet. Remember, each
                  transaction on Ethereum costs Ether (ETH). For a more detailed
                  walkthrough, check out our Help Guides.
                  <br />
                  <br />
                  If you’re a developer interested in building on top of the
                  Uniswap Protocol, please refer to our extensive docs.
                  <br />
                  <br />
                </p>
              </div>
              <div className="horizontal-line"></div>
              <div className="uniswap">
                <h4>How does Uniswap Protocol work?</h4>
                <br />
                <p>
                  Uniswap is an automated market maker. In practical terms, it
                  is a collection of smart contracts that define a standard way
                  to create liquidity pools, provide liquidity, and swap assets.
                  <br />
                  <br />
                  Each liquidity pool contains two assets. The pools keep track
                  of aggregate liquidity reserves and the pre-defined pricing
                  strategies set by liquidity providers. Reserves and prices are
                  updated automatically every time someone trades. There is no
                  central order book, no third-party custody, and no private
                  order matching engine. <br /> <br />
                  Because reserves are automatically rebalanced after each
                  trade, a Uniswap pool can always be used to buy or sell a
                  token — unlike traditional exchanges, traders do not need to
                  match with individual counterparties to complete a trade.
                  <br />
                  <br />
                  For a more in-depth description, check out the Concepts from
                  the documentation.
                </p>
              </div>
            </div>

            <div className="box2">
              <div className="floatsys">
                <h5>Discord</h5>
                <h5>Help Center</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
