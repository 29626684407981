import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Pages/Navbar/Navbar";
import Footer from "./Pages/Footer/Footer";
import Home from "./Components/Layout/Home/Home";
import Faq from "./Components/Layout/FAQ/Faq";
import Ecosystem from "./Components/Layout/Ecosystem/Ecosystem";
import Community from "./Components/Layout/Community/Community";
import Governance from "./Components/Layout/Governance/Governance";
import Developer from "./Components/Layout/Developer/Developer";
import Swap from "./Components/Layout/Swap/Swap";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ecosystem" element={<Ecosystem />} />
          <Route path="/community" element={<Community />} />
          <Route path="/governance" element={<Governance />} />
          <Route path="/developer" element={<Developer />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/swap" element={<Swap/>}/>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
